<template>
  <div
    class="app-container apply__myApply"
    style="background-color: #f6f6f6; padding: 180px 0px 150px; min-height: 800px;"
  >
    <!-- 비밀번호 초기화 form -->
    <div style="width: 500px; margin: 0 auto;">
      <div class="apply__myApply--container">
        <div class="align-top">
          <h1 class="apply__tit font-bold text-2xl mt-5 mb-12 mt-10 pb-3">
            <span class="highlight">
              <span>🔑 비밀번호 초기화</span>
            </span>
            <p class="text-sm font-normal mt-3">
              지원서에 제출한 이메일을 입력해 주세요.
            </p>
          </h1>
          <el-form
            class="custom-form"
            :model="initForm"
            inline
            style="width: 300px; margin: 0 auto;"
          >
            <div>
              <el-form-item prop="name">
                <el-input
                  v-model="initForm.name"
                  :placeholder="$t('recruit.form.name')"
                  name="namefield"
                  class="filter-item"
                  style="width: 300px"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="birthday">
                <el-input
                  v-model="initForm.birthday"
                  :maxlength="10"
                  :placeholder="$t('recruit.form.birthday')"
                  class="filter-item"
                  style="width: 300px"
                  @keydown.native="autoCompleteBirthday"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item
                prop="email"
                class="mb-10"
              >
                <el-input
                  v-model="initForm.email"
                  :placeholder="$t('recruit.form.email')"
                  type="email"
                  class="filter-item"
                  style="width: 300px"
                  @keydown.native.enter="initPassword"
                />
              </el-form-item>
            </div>
          </el-form>
          <div class="applyNew__btnWrap">
            <button
              style="width: 220px; height: 60px;"
              class="mt-4 customBtn lg white"
              @click="initPassword"
            >
              <span style="font-size: 18px;">비밀번호 재발급</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- // 비밀번호 초기화 form -->
    <!-- 비밀 번호 재발급 로딩 -->
    <div
      v-show="pwdLoading"
      class="loading__modal"
    >
      <div class="tsmd__dim" />
      <div class="cookie__loading">
        <div class="cookie__loading--img">
          <img
            class="cookie"
            style="width: 400px;"
            src="@/assets/recruit/recruit_loading.gif"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- // 비밀 번호 재발급 로딩 -->
  </div>
</template>

<script>
import { initPassword } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  name: 'PasswordPage',
  data() {
    return {
      initForm: {
        name: '',
        birthday: '',
        email: ''
      },
      pwdLoading: false,
    };
  },
  methods: {
    autoCompleteBirthday: function (key) {
      const birthday = this.initForm.birthday;
      const year = birthday.substr(0,4);
      const firstDot = birthday.substr(4,1);
      const month = birthday.substr(5,2);
      const secondDot = birthday.substr(7,1);
      if( key.keyCode == 8 || key.keyCode == 46 || key.key === '.' ) return false;
      if(birthday.length > 3 && firstDot !== '.'){
        this.initForm.birthday = year + '.';
      }else if(birthday.length > 6 && secondDot !== '.'){
        this.initForm.birthday = year + '.' + month + '.';
      }
    },
    validateBirthday() {
      let isBirthdayValid = false;
      const birthday = this.initForm.birthday;
      var regex = /^(19[0-9][0-9]|20\d{2}).(0[0-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])$/;
      if(!regex.test(birthday)){
        this.$message({
          message: '생년월일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isBirthdayValid = true;
      }
      return isBirthdayValid;
    },
    validateEmail() {
      let isEmailValid = false;
      const email = this.initForm.email;
      const regex = /\S+@\S+\.\S+/;
      if(!regex.test(email)){
        this.$message({
          message: '이메일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isEmailValid = true;
      }
      return isEmailValid;
    },
    validate() {
      if(!this.initForm.name.length > 0){
        this.$message({
          message: '이름을 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.initForm.birthday.length > 0){
        this.$message({
          message: '생년월일을 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.initForm.email.length > 0){
        this.$message({
          message: '이메일을 입력해주세요.',
          type: 'error'
        });
        return false;
      }
      return true;
    },
    async initPassword() {
      if(!this.validate() || !this.validateBirthday() || !this.validateEmail() ){
        return false;
      }
      this.pwdLoading = true;
      const result = await initPassword({
        name: this.initForm.name,
        birthday: this.initForm.birthday,
        email: this.initForm.email,
      });
      this.pwdLoading = false;
      if(!result.data.success){
        this.$message({
          message: result.data.message,
          type: 'error'
        });
        return false;
      }
      this.$message({
        message: result.data.email+'로 임시비밀번호를 발급하였습니다.',
        type: 'success'
      });
      this.$router.push('/recruit/my-apply');
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/apply.scss';
</style>
